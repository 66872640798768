import React from 'react';
import Newsfeed from '../news_feed/news_feed';
class Feed extends React.Component {

   
    render() {
        return (
            <div>
                <Newsfeed />
            </div>
        );
    }
}

export default Feed;


